<template>
  <nav
    :class="classes"
    class="navbar navbar-expand-lg"
  >
    <div class="container-fluid">
      <slot />
    </div>
  </nav>
</template>
<script>

export default {
  name: "MainNavbar",
  model: {
    prop: "showNavbar",
    event: "change"
  },
  props: {
    showNavbar: {
      type: Boolean,
      description: "Whether navbar is visible"
    },
    navbarMenuClasses: {
      type: [String, Object],
      description: "Navbar menu css classes",
      default: ""
    },
    transparent: {
      type: Boolean,
      default: true,
      description: "Whether navbar is transparent"
    },
    position: {
      type: String,
      default: "absolute",
      description: "Navbar position (absolute|fixed|relative)"
    },
    type: {
      type: String,
      default: "white",
      validator(value) {
        return ["white", "default", "primary", "danger", "success", "warning", "info"].includes(value);
      },
      description: "Navbar type (primary|info|danger|default|warning|success)"
    }
  },
  emits: ["change"],
  computed: {
    classes() {
      let color = `bg-${this.type}`;
      let navPosition = `navbar-${this.position}`;
      return [
        { "navbar-transparent": !this.showNavbar && this.transparent },
        { [color]: this.showNavbar || !this.transparent },
        navPosition];
    }
  },
  methods: {
    toggleMenu() {
      this.$emit("change", !this.showNavbar);
    }
  }
};
</script>
<style scoped>
.navbar-relative {
  position: relative;
}
</style>
